import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const img = {
  margin: "0"
}

const box = {
  backgroundColor: "#eaf0f6",
  padding: "24px",
  marginBottom: "16px"
}

const btn = {
  color: "#f2545b",
  border: "1px solid #f2545b",
  padding: "8px 16px",
  borderRadius: "4px",
  display: "block",
  margin: "auto",
  textAlign: "center"
}

const Courses = () => (
  <Layout>
    <SEO title="Best Online Courses" />
    <h1>Best Online Courses</h1>
    <p>Each course linked below is bestseller and verified. Members can learn everything from the basics to the most advanced topics.</p>
    <div className="row">

      <div className="col">
        <a rel="sponsored" href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.851712&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-javascript-course%2F"><img style={img} alt="The Complete JavaScript Course 2020: Build Real Projects!" src="https://i.udemycdn.com/course/480x270/851712_fc61_5.jpg" /></a><img border={0} width={1} height={1} src="https://ad.linksynergy.com/fs-bin/show?id=LFcSQV4ezac&bids=507388.851712&type=2&subid=0" />
          <div style={box}>
            <h2>The Complete JavaScript Course 2020: Build Real Projects!</h2>
            <p>27 Hours | 166 Lessons</p>
            <p>Master JavaScript with the most complete course! Projects, challenges, quizzes, JavaScript ES6+, OOP, AJAX, Webpack</p>
            <a style={btn} href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.851712&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-javascript-course%2F">Read Course Details</a>
          </div>
      </div>

      <div className="col">
      <a rel="sponsored" href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.1362070&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-the-complete-guide-incl-redux%2F"><img style={img} alt="React - The Complete Guide (incl Hooks, React Router, Redux)" src="https://i.udemycdn.com/course/480x270/1362070_b9a1_2.jpg" /></a><img border={0} width={1} height={1} src="https://ad.linksynergy.com/fs-bin/show?id=LFcSQV4ezac&bids=507388.1362070&type=2&subid=0" />
        <div style={box}>
          <h2>React - The Complete Guide (incl Hooks, React Router, Redux)</h2>
          <p>45 Hours | 543 Lessons</p>
          <p>Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!</p>
          <a style={btn} href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.1362070&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-the-complete-guide-incl-redux%2F">Read Course Details</a>
        </div>
      </div>

      <div className="col">
      <a rel="sponsored" href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.922484&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-nodejs-developer-course-2%2F"><img style={img} alt="Learn Node. js by building real-world applications with Node, Express, MongoDB, Jest, and more!" src="https://i.udemycdn.com/course/480x270/922484_52a1_8.jpg" /></a><img border={0} width={1} height={1} src="https://ad.linksynergy.com/fs-bin/show?id=LFcSQV4ezac&bids=507388.922484&type=2&subid=0" />
        <div style={box}>
          <h2>The Complete Node. js Developer Course (3rd Edition)</h2>
          <p>34 Hours | 177 Lessons</p>
          <p>Learn Node. js by building real-world applications with Node, Express, MongoDB, Jest, and more!</p>
          <a style={btn} href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.922484&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-nodejs-developer-course-2%2F">Read Course Details</a>
        </div>
      </div>
    </div>
  </Layout>
)

export default Courses